<template>
   <div class="cropper-box">
      <cropper class="cropper" :src="upload" :canvas="{ minHeight: 256, minWidth: 256, maxHeight: 1280, maxWidth: 1280 }" :stencil-props="{ aspectRatio: ratio == '24x7' ? 24/7 : 1/1 }" :auto-zoom="true" :backgroundClass="'bg-white'" @change="change" />

      <div class="results">
         <div class="results__wrapper" v-if="temp.preview">
            <div class="results__preview" :class="'r'+ ratio"><img :src="temp.preview" /></div>
            <div class="results__coordinates">
               <i class="far fa-check text-green" @click="set"></i>
               <i class="far fa-times text-red" @click="reset"></i>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'CropperBox',
   props: ['upload', 'ratio'],
   data: function () {
      return {
         temp: {'preview': null}
      }
   },
   methods: {
      set : function () {
         this.$emit('set', this.temp.preview)
      },
      reset : function () {
         this.$emit('reset')
      },
      /* eslint-disable-next-line no-unused-vars */
      change : function ({ coordinates, canvas }) {
         this.temp.preview = canvas.toDataURL('image/png');
		}
   }
}

</script>